import * as React from 'react';
import { FC } from 'react';
import Toolbar from '@mui/material/Toolbar';
import { Box, ListItemButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import logo from '../tests/testAdmin/testList/navigation/logo.png';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import { useUserRoles } from '../../auth/auth.hook';
import { UserRole } from '../../auth/user-role';
import { StagingAlert } from './staging-alert';
import {
  NavigationGroupEntry,
  NavigationPageLinkEntry,
} from './NavigationEntry';

export const drawerWidth = 200;

export interface PageLink {
  label: string;
  tabPath: string;
  icon: JSX.Element;
  forRoles?: UserRole[];
}

export interface AdminAppDrawerProps {
  primaryPages: (NavigationPageLinkEntry | NavigationGroupEntry)[];
  secondaryPages: NavigationPageLinkEntry[];
}

function NavigationGroupEntryItem({ page }: { page: NavigationGroupEntry }) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <ListItemButton
        onClick={() => {
          setOpen(!open);
        }}
      >
        <ListItemIcon>{page.icon}</ListItemIcon>
        <ListItemText primary={page.label} />
      </ListItemButton>
      {open && (
        <Box sx={{ ml: '1.5em' }}>
          <NavigationEntryList entryList={page.children} />
        </Box>
      )}
    </>
  );
}

function NavigationEntryList({
  entryList,
}: {
  entryList: (NavigationPageLinkEntry | NavigationGroupEntry)[];
}) {
  const location = useLocation();

  const userRoles = useUserRoles();
  return (
    <List>
      {entryList
        .filter(
          (page) =>
            page.forRoles === undefined ||
            page.forRoles.some((r) => userRoles.includes(r)),
        )
        .map((page, index) => {
          if (page.type === 'page-group') {
            return <NavigationGroupEntryItem key={page.label} page={page} />;
          }
          return (
            <ListItemButton
              selected={location.pathname.includes(page.tabPath)}
              key={page.label}
              component={Link}
              to={page.tabPath}
            >
              <ListItemIcon>{page.icon}</ListItemIcon>
              <ListItemText primary={page.label} />
            </ListItemButton>
          );
        })}
    </List>
  );
}

export const AdminAppDrawer: FC<AdminAppDrawerProps> = ({
  primaryPages,
  secondaryPages,
}) => {
  const location = useLocation();

  // const userRoles = useUserRoles();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar
        sx={{
          height: 90,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          '& img': {
            height: 20,
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt="logo" />
        </Box>
      </Toolbar>
      <Divider />
      <StagingAlert />
      <NavigationEntryList entryList={primaryPages} />
      {/*  <List>{primaryPages
          .filter(
            (page) =>
              page.forRoles === undefined ||
              page.forRoles.some((r) => userRoles.includes(r)),
          )
          .map((page, index) => (
            <ListItemButton
              selected={location.pathname.includes(page.tabPath)}
              key={page.label}
              component={Link}
              to={page.tabPath}
            >
              <ListItemIcon>{page.icon}</ListItemIcon>
              <ListItemText primary={page.label} />
            </ListItemButton>
          ))} </List>*/}

      <Divider />
      <Box sx={{ mt: 'auto' }}>
        <Divider />
        <List>
          {secondaryPages.map((page, index) => (
            <ListItemButton
              selected={location.pathname.includes(page.tabPath) && index !== 0}
              key={page.label}
              component={Link}
              to={page.tabPath}
            >
              <ListItemIcon>{page.icon}</ListItemIcon>
              <ListItemText primary={page.label} />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};
